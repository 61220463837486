<template>
  <v-card>
    <v-container class="mb-5">
      <v-btn small outlined @click="retornarParaFiltro" color="accent">
        <v-icon left>mdi-arrow-left-bold</v-icon>
        Voltar
      </v-btn>
      <v-row style="align-items: center" justify="center">
        <v-card-title class="justify-center titulo">
          <h3>{{ this.opcao.nome }}</h3>
        </v-card-title>
      </v-row>
    </v-container>
    <v-card elevation="0">
      <v-card-title class="mx-1">
        Candidatos
        <v-spacer/>
        <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
            name="buscarOpcao"
            dense
        ></v-text-field>
      </v-card-title>
      <v-form ref="documentoForm">
        <v-card-text>
          <v-data-table
              v-if="inscricoes.length > 0"
              :headers="headersInscricao"
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="25"
              :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [25, 50, 100]
                }"
              item-key="numeroInscricao"
              :items="inscricoes"
              disable-sort
              class="elevation-1"
              :search="buscar"
          >
            <template v-slot:item.situacao="{ item }">
              <div v-if="item.situacao">
                <div v-if="item.pontuacao">
                  <v-icon v-if="item.convocado" left size="20" color="primary">mdi-check-circle-outline</v-icon>
                  <v-icon v-else left color="warning" size="20">mdi-clock-time-two-outline</v-icon>
                  <span>{{item.situacao}}</span>
                </div>
                <span v-else>{{item.situacao}}</span>
              </div>
              <div v-else class="d-flex align-center">
                <v-icon left color="error" size="20">mdi-alert-circle-outline</v-icon>
                <span>-</span>
              </div>
            </template>
            <template v-slot:item.convocado="{ item }">
              <div v-if="item.pontuacao">

              </div>
              <div v-else class="d-flex align-center">
                <v-icon left color="error" size="20">mdi-alert-circle-outline</v-icon>
                <span>Não classificado</span>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top :open-delay="defaultDelay">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="justify-center"
                      icon
                      color="accent"
                      @click.stop="downloadDocumentosParaVisualizacao(item)"
                  >
                    <v-icon>
                      mdi-file-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Visualizar documentos</span>
              </v-tooltip>
              <v-tooltip top :open-delay="defaultDelay">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="justify-center"
                      icon
                      color="info"
                      @click.stop="downloadDocumentos(item)"
                  >
                    <v-icon>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Baixar documentos</span>
              </v-tooltip>
            </template>

            <template v-slot:footer.prepend>
              <v-spacer></v-spacer>
              <span class="mr-6">Página:</span>
              <div class="text-center pt-2">
                <v-sheet max-width="80" class="mr-6">
                  <v-select
                      style="font-size: 0.75rem"
                      :items="
                          Array.from({ length: pageCount }, (v, k) => k + 1)
                        "
                      v-model="page"
                  >
                  </v-select>
                </v-sheet>
              </div>
            </template>
          </v-data-table>
          <v-row v-else-if="loading" align="center" justify="center">
            <v-alert type="info" border="left" colored-border outlined>
              Não existem inscrições para visualização de documentos na opção selecionada.
            </v-alert>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>

    <v-dialog scrollable v-if="dialogDocuments" v-model="dialogDocuments" width="80%">
      <v-card>
        <v-card-title class="primary justify-center white--text">
          Documentos
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="dialogDocuments = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-tabs v-model="documentosTab" show-arrows>
          <v-tab v-for="arquivo in arquivosVisualizacao" :key="arquivo.nome">
            <span class="d-inline-block text-truncate"> {{ arquivo.nome }} </span>
            <v-chip v-if="!!arquivo.faseEnvio"
                    text-color="white"
                    color="info"
                    class="ml-1"
                    small>
              <span class="text-truncate">
                  {{ arquivo.faseEnvio }}
              </span>
            </v-chip>
          </v-tab>
        </v-tabs>
        <v-card-text style="overflow: hidden">
          <v-tabs-items v-model="documentosTab">
            <v-tab-item
                v-for="arquivo in arquivosVisualizacao"
                :key="arquivo.nome"
            >
              <DocumentsViewer :file="arquivo"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import documentosService from "@/services/documentos.service";
import rules from "@/commons/rules";
import {mapActions, mapGetters} from "vuex";
import DocumentsViewer from "@/components/base/DocumentsViewer";

export default {
  name: "Lancamento",
  components: {DocumentsViewer},
  props: {
    processo: {type: Object, required: true},
    opcao: {type: Object, required: true}
  },

  data() {
    return {
      page: 1,
      buscar: '',
      pageCount: 0,
      loading: 0,
      defaultDelay: 1000,
      inscricoes: [],
      arquivosVisualizacao: [],
      dialogDocuments: false,
      inscricaoSelecionada: {},
      documentosTab: 0
    };
  },

  async created() {
    const opcaoOid = this.opcao.oid;
    const loader = this.$loading.show();

    await documentosService
        .recuperarInscricoesVisualizacaoDocumentosPorOpcao(opcaoOid)
        .then(response => {
          this.inscricoes = response.data;
        })
        .catch(() => {
          this.exibirAviso({
            mensagem: "Não foi possivel recuperar inscrições",
            tipo: "error"
          });
          loader.hide();
        });

    this.loading = true;
    loader.hide();
  },

  computed: {
    headersInscricao() {
      let headersInscricao = [
        {text: "Nome do Candidato", value: "nomeCandidato"},
        {text: "Número de Inscrição", value: "numeroInscricao"},
        {text: "Situação Inscrição", value: "situacao"}
      ];

      if (this.processo.possuiEnvioDocumentos) {
        headersInscricao.push({
          text: "Documentos",
          value: "actions",
          align: "center"
        });
      }
      return headersInscricao;
    }
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),
    ...mapGetters("autenticacao", ["isAdministrador", "isAvaliador"]),

    async retornarParaFiltro() {
      this.$emit("voltar");
    },

    async downloadDocumentosParaVisualizacao(inscricao) {
      let loader = this.$loading.show();
      await documentosService
          .downloadDocumentosCandidatoParaVisualizacao(inscricao.inscricaoOid)
          .then(response => {
            this.arquivosVisualizacao = response.data;
            this.dialogDocuments = true;
          })
          .catch(error => {
            let mensagem = "Não foi possível realizar download";
            if (error.response.status === 409) {
              mensagem = error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });
          });
      loader.hide();
    },

    downloadDocumentos(inscricao) {
      documentosService.downloadDocumentosCandidato(inscricao.inscricaoOid).then(response => {
        const file = new Blob([response.data]);
        const fileURL = URL.createObjectURL(file);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = inscricao.cpfCandidato + ".zip";
        fileLink.click();
      }).catch(async error => {
        let mensagem = "Não foi possível realizar download";
        if (error.response.status === 409) {
          await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = function () {
              resolve(JSON.parse(reader.result.toString()));
            };
            reader.readAsText(error.response.data);
          }).then(erro => {
            mensagem = erro.msg;
          }).catch();
        }
        this.exibirAviso({
          mensagem: mensagem,
          tipo: "error"
        });
      });
    }
  }
};
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  white-space: unset;
}
</style>
