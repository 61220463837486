<template>
  <v-container fluid v-if="!loading">
    <v-card rounded width="100%" v-if="processos.length">
      <v-card-title class="justify-center mb-3 titulo">
        <h2>
          Visualização de Documentos
        </h2>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <EscolhaProcessoAutocomplete
                  :disabled="tab === 1"
                  :processos="processos"
                  v-model="processoSelecionado"
              />
            </v-col>
          </v-row>
          <v-divider class="my-5"/>
          <v-window v-model="tab">
            <v-window-item>
              <v-card
                  v-if="
                  opcoes.length > 0 &&
                    Object.keys(processoSelecionado).length > 0
                "
              >
                <v-card-title>
                  Opções
                  <v-spacer/>
                  <v-text-field
                      v-model="buscar"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details
                      name="buscarOpcao"
                      dense
                  ></v-text-field>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                      disable-pagination
                      hide-default-footer
                      :headers="headers"
                      :items="opcoes"
                      :search="buscar"
                      @click:row="carregarOpcao"
                      class="elevation-1"
                  >
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-window-item>
            <v-window-item>
              <Lancamento
                  v-if="tab === 1"
                  @voltar="voltarTab"
                  @lancamentoRealizado="atualizarOpcoes"
                  :opcao="opcaoSelecionada"
                  :processo="processoSelecionado"
              />
            </v-window-item>
          </v-window>
        </v-container>
      </v-card-text>
    </v-card>
    <v-row v-else align="center" class="mt-10" justify="center">
      <v-alert type="info" prominent colored-border border="left" elevation="1">
        Não existem processos para visualização de resultados
      </v-alert>
    </v-row>
    <v-row v-if="processoSelecionado.oid && !opcoes.length && !loadingOpcoes" align="center" class="mt-10"
           justify="center">
      <v-alert type="info" prominent colored-border border="left" elevation="1">
        Não existem opções para visualização de resultados
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import EscolhaProcessoAutocomplete from "@/components/base/EscolhaProcessoAutocomplete";
import documentosService from "@/services/documentos.service";
import Lancamento from "./Documento";

export default {
  name: "FiltroLancamento",

  components: {Lancamento, EscolhaProcessoAutocomplete},

  async created() {
    await documentosService
        .recuperarProcessosVisualizacaoDocumentos()
        .then(response => {
          this.processos = response.data;
          this.loading = false;
        })
        .catch(() => {
        });
  },

  watch: {
    processoSelecionado: {
      async handler() {
        if (this.processoSelecionado.oid) {
          const loader = this.$loading.show();
          this.loadingOpcoes = true;
          await documentosService
              .recuperarOpcoesPorProcesso(this.processoSelecionado.oid)
              .then(response => {
                this.opcoes = response.data;
                this.loadingOpcoes = false;
              });
          loader.hide();
        } else {
          this.opcoes = [];
          this.loadingOpcoes = false;
        }
      }
    }
  },

  methods: {
    async atualizarOpcoes() {
      await documentosService
          .recuperarOpcoesPorProcesso(this.processoSelecionado.oid)
          .then(response => {
            this.opcoes = response.data;
          });
    },

    async carregarOpcao(opcao) {
      this.opcaoSelecionada = opcao;
      this.tab = 1;
    },

    voltarTab() {
      this.tab = 0;
    }
  },

  data() {
    return {
      loading: true,
      loadingOpcoes: true,
      processoSelecionado: {},
      processos: [],
      opcaoSelecionada: {},
      opcoes: [],
      tab: 0,
      buscar: ""
    };
  },

  computed: {
    headers() {
      return [
        {text: "Nome", value: "nome"}
      ];
    },
  }
};
</script>

<style>
.v-data-table__wrapper {
  cursor: pointer;
}
</style>
